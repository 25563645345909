@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import 'helpers';

* {
	box-sizing: border-box;
}

html, body {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	background: url(../img/background/background.jpg) repeat;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

li {
	margin: 0;
	padding: 0;
}

p {
	font: 14px Calibri, Arial, Helvetica, sans-serif;
	color: black;
	margin: 0;
	padding: 0;
}

img {
	border: none;
	text-decoration: none;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

table {
	margin: 0;
	padding: 0;
	border-collapse: collapse;
}

form {
	margin: 0;
	padding: 0;
}

// Header
.header {
	background: #F29503;
	border-bottom: 4px solid white;
	box-shadow: 0 2px 3px 0 rgba(black, 0.5);
	margin-bottom: 30px;
	font-family: sans-serif;

	&__contenedor {
		max-width: 860px;
		margin: 0 auto;
		padding: 15px 0;
		display: flex;
		align-items: center;
		position: relative;
	}

	// Logotipo
	&__logo {
		flex-shrink: 0;
	}

	// "Worcester Shire" (img) + nav
	&__titulo-nav {

		> img {
			margin-bottom: 15px;
		}

	}

	// Nav
	&__nav {
		font-family: "Pacifico", sans-serif;
		font-size: 22px;
		display: flex;

		li {
			margin: 0 10px;
		}

		a {
			color: #3d2510;
			transition: all .25s ease;

			&:hover {
				color: white;
				text-decoration: none;
			}

			&.current {
				color: white;

				&:hover {
					cursor: default;
				}
			}

		}

	}

	// Idioma y fecha
	&__idioma-fecha {
		position: absolute;
		right: 10px;
		top: 10px;
		display: flex;
	}

	// Idioma
	&__idioma {
		display: flex;
		order: 2;

		li {
			margin-left: 15px;

			&.es {

				a {
					background-image: url("/img/header/flag-es.png");
				}

			}

			&.en {

				a {
					background-image: url("/img/header/flag-en.png");
				}

			}

			&.selected {

				a {
					color: white;
					text-decoration: underline;
				}

			}

		}

		a {
			padding-left: 35px;
			background-repeat: no-repeat;
			background-size: auto 100%;
			background-position: 0 50%;
			color: #222222;
		}

	}


	// Fecha
	&__fecha {
		order: 1;
		margin-right: 15px;
		
		> p {
			text-align: right;
		}
		
	}

	@media(max-width: 810px) {

		&__contenedor {
			padding-top: 25px;
		}

		&__fecha {

			> p {
				display: inline-block;
			}

		}

	}

	@media(max-width: 630px) {

		&__contenedor {
			display: block;
		}

		&__logo {
			text-align: center;
			margin-bottom: 15px;
		}

		&__titulo-nav {
			display: flex;
			align-items: center;

			> img {
				width: 100px;
				height: 28.90px;
				margin-bottom: 0;
				display: block;
			}

		}

	}

	@media(max-width: 540px) {

		&__fecha {
			display: none;
		}

		&__titulo-nav {

			> img {
				margin-right: 15px;
			}

		}

		&__nav {
			flex-wrap: wrap;

			> li {
				flex-basis: 50%;
				margin: 0;
			}

		}

	}

	@media(max-width: 380px) {

		&__contenedor {
			padding-top: 5px;
		}

		&__logo {
			text-align: left;
			padding-left: 15px;

			// Ratio 1.39
			img {
				width: 100px;
				height: 71.94px;
			}

		}

		&__titulo-nav {

			> img {
				display: none;
			}

		}

		&__nav {
			font-size: 18px;
			flex-basis: 100%;

			> li {
				text-align: center;
			}

		}

	}

}

// Footer
.footer {
	border-top: 4px solid white;
	background: #E85426;
	box-shadow: 0 -2px 3px 0 rgba(black, 0.5);

	&__contenedor {
		max-width: 860px;
		margin: 0 auto;
		padding: 20px 15px;
		display: flex;
		justify-content: space-between;
	}

	&__direccion {
		background: url(/img/footer/house.png) no-repeat 0 50%;
		padding: 0 0 0 35px;
		margin-right: 7.5px;

		> h1 {
			font: bold 18px Arial, Helvetica, sans-serif;
			color: white;
		}

		> p {
			font: 15px Arial, Helvetica, sans-serif;
			color: white;
		}

	}

	&__links {
		margin-left: 7.5px;

		p {
			color: white;
		}

		a {
			font-size: 15px;
			color: white;
		}

	}

	@media(max-width: 540px) {

		&__contenedor {
			display: block;
		}

		&__direccion {
			margin-right: 0;
			margin-bottom: 25px;
		}

		&__links {
			margin-left: 0;
			text-align: center;
		}

	}

}

// Banner
.banner {

	&__contenedor {
		max-width: 860px;
		margin: 0 auto;
		padding-right: 70px;
		position: relative;
	}

	&__salsa {
		position: absolute;
		right: 0;
		top: -150px;
		z-index: 50;
	}

	.owl-nav {
		margin-top: 15px;
	}

	.owl-prev,
	.owl-next {
		font-size: 58px !important;
		background: white !important;
		border-radius: 50%;
		width: 58px;
		height: 58px;
		position: relative;

		> span {
			position: absolute;
			left: 50%;
			top: -10px;
			transform: translateX(-50%);
		}

		&:focus {
			outline: none;
		}

	}

	.owl-next {
		margin-left: 15px;
	}

	@media(max-width: 760px) {

		&__salsa {
			top: 15px;
			right: 5px;

			img {
				width: 85px;
				height: auto;
			}

		}

	}

	@media(max-width: 374px) {

		&__contenedor {
			margin-bottom: 25px;
		}

	}

}
