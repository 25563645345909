.contenedor {
	max-width: 860px;
	margin: 0 auto;

	&_padding {
		padding: 0 2%;
	}

}

.titulo {
	font-family: 'Pacifico', cursive;
	font-size: 28px;
	font-style: italic;
	color: #3D2510;

	&_subrayado {
		border-bottom: 2px solid rgba(#3d2510, .3);
	}

	&_margen-inferior {
		margin-bottom: 16px;
	}

}

@mixin parrafo {
	font-family: Georgia, "Times New Roman", serif;
	font-style: italic;
	font-size: 16px;
	margin: 16px 0;
}